/* eslint-disable */

import IMask from 'imask';
import Choices from 'choices.js';

export default (() => {

  const phonesFields          = document.querySelectorAll('input[data-mask^="phone"]');
  const customFieldsMask      = document.querySelectorAll('input[data-mask^="custom"]');
  const selects               = document.querySelectorAll('.ui-select');
  const numbersFieldsMask     = document.querySelectorAll('input[data-mask^="numbers"]');
  const gbpFieldsMask         = document.querySelectorAll('input[data-mask^="gbp"]');
  const azFieldsMask          = document.querySelectorAll('input[data-mask^="az"]');

  Array.from(phonesFields).forEach((field) => {
    const customMask = field.getAttribute('data-mask').split('|')[1]
    const mask = customMask || '000000000000000'
    field.imask = IMask(field, {
      lazy: true,
      // mask,
      mask: '{+44}`NM',
      blocks: {
        NM: {
          mask: '000000000000000'
        }
      }
    });
    // field.addEventListener('focus', (e) => {
    //   e.preventDefault();
    //   field.imask.updateOptions({
    //     lazy: false
    //   })
  	// });
    // field.addEventListener('paste', (e) => {
  	// 	e.preventDefault();
  	// 	let clipboardCurrentData = (e.originalEvent || e).clipboardData.getData('text/plain').replace(/[^0-9]/g, "").split('');

  	// 	if(clipboardCurrentData.length >= 11)
  	// 		clipboardCurrentData = clipboardCurrentData.slice(1, clipboardCurrentData.length)

  	// 	window.document.execCommand('insertText', false, clipboardCurrentData.join(''));
  	// });
  });

  Array.from(customFieldsMask).forEach((field) => {
    const mask = field.getAttribute('data-mask').split('|')[1];
    if(!mask){
      console.error(`Не указана маска у поля ${field.name}`)
      return
    }
    field.imask = IMask(field, {
      mask
    });
  })

  Array.from(gbpFieldsMask).forEach((field) => {
    const mask = field.getAttribute('data-mask');
    field.imask = IMask(field, {
      mask: Number,
      radix: '.',
    });
  })

  Array.from(numbersFieldsMask).forEach((field) => {
    const mask = field.getAttribute('data-mask');
    field.imask = IMask(field, {
      mask: Number,
    });
  })
  Array.from(azFieldsMask).forEach((field) => {
    const mask = field.getAttribute('data-mask');
    field.imask = IMask(field, {
      mask: /^[A-Za-z]+$/,
    });
  })
  Array.from(selects).forEach((item) => {
    item.choices = new Choices(item, {
      placeholder: true,
      searchEnabled: false,
      shouldSort: false,
      itemSelectText: '',
    });
  });
  document.querySelectorAll('.form-control-container').forEach(el => {
    const placeholder = el.querySelector('.form-control-placeholder')
    let width = el.offsetWidth - placeholder.offsetWidth - 26;
    let text = el.querySelector('.form-control-js-text')
    el.querySelector('.form-control').addEventListener('input', (e) => {
      text.innerText = e.target.value;
      if(text.offsetWidth >= width) {
        placeholder.classList.add('hidden')
      } else {
        placeholder.classList.remove('hidden')
      }
      if(e.target.value.length) {
        placeholder.classList.add('moved')
      } else {
        placeholder.classList.remove('moved')
      }
    })
    if(el.querySelector('[data-mask="phone"]')) {
      el.querySelector('[data-mask="phone"]').addEventListener('focus', (e) => {
        text.innerText = e.target.value;
        if(text.offsetWidth >= width) {
          placeholder.classList.add('hidden')
        } else {
          placeholder.classList.remove('hidden')
        }
        if(e.target.value.length) {
          placeholder.classList.add('moved')
        } else {
          placeholder.classList.remove('moved')
        }
      })
    }
  });
  $('[data-sub-url]').click(function() {
    let form = $(this).closest( "form" )
    let $self = $(this);
    if(form.find("[id='Website Payments']").is(':checked') || form.find("[id='Pay by Link']").is(':checked')) {
      redirectTriggerHref()
    }
    function redirectTriggerHref(){
      form.append( $( `<a class="hidden" href="${$self.data( "sub-url" )}email=${form.find('[name=email]').val()}&first-name=${form.find('[name=name]').val()}&surname=${form.find('[name=surname]').val()}&phone=${form.find('[name=phone]').val()}&company=${form.find('[name=company]').val()}" target="_blank"></a>` ) );
      form[0].querySelector('a.hidden').click()
    }
    // window.open(`${$(this).data( "sub-url" )}email=${form.find('[name=email]').val()}&first-name=${form.find('[name=name]').val()}&surname=${form.find('[name=surname]').val()}&phone=${form.find('[name=phone]').val()}`);
  });
  let serializeData;
  let serializeDataIf = false;
  $('[data-step="form"] [type="submit"]').click(function() {
    serializeData = $(this).closest( "form" ).serialize()
    serializeDataIf = true;
  })
  $('[data-step="form"]').on('ajaxSuccess', function() {
    if(serializeDataIf) {
      $.ajax({
        type: "POST",
        url: '/formlog/send',
        data: serializeData, // serializes the form's elements.
        success: function(data)
        {
            console.log(data); // show response from the php script.
        }
      });
    }
  })
  $('[data-step="form"]').on('ajaxError', function() {
    serializeDataIf = false;
  })



  const data8Validate = (value) => {
    return new Promise((resolve, reject) => {
      const showIsValidResult = result => {
        if (!result.Status.Success) {
          reject(result)
          // alert('Error: ' + result.Status.ErrorMessage);
        }
        else {
          resolve(result)
          // console.log(result,result.Result.CountryCode === "GB" && result.Result.ValidationResult === "Valid")
        }
      }
      var internationaltelephonevalidation = new data8.internationaltelephonevalidation();
        internationaltelephonevalidation.isvalid(
          value,
          'GB',
          [
            new data8.option('UseMobileValidation', 'false'),
            new data8.option('UseLineValidation', 'false'),
            new data8.option('RequiredCountry', ''),
            new data8.option('AllowedPrefixes', ''),
            new data8.option('BarredPrefixes', 'true'),
            new data8.option('UseUnavailableStatus', 'true'),
            new data8.option('UseAmbiguousStatus', 'true'),
            new data8.option('TreatUnavailableMobileAsInvalid', 'false'),
            new data8.option('ExcludeUnlikelyNumbers', 'false')
          ],
          showIsValidResult
        )
    })
  }
  Validator.registerAsync('phoneRequest', function(value, attribute, req, passes) {
    if(value.length >= 11) {
      data8Validate(value).then(result => {
        let valid = result.Result.CountryCode === "GB" && result.Result.ValidationResult === "Valid"
        passes(valid);
      })
    } else {
      passes(false)
    }
  });
  if(document.querySelector('._js-obsr-input-email')) {
    document.querySelector('._js-obsr-input-email').value = window.location.href.split('#=')[1]
  }
})();
