import Alpine from 'alpinejs'
import './lib/store'
import 'bootstrap.native';
import './validation/index'
import './lib/carousel'
import './lib/carousel-promo'
// import './lib/carousel-column'
import './lib/header'
import './lib/carousel-banner'
import './lib/map'
import './lib/collapse'
// import './lib/scrolltop'
import './lib/forms'
import { cookieBar } from './lib/cookie-bar'
import './lib/carousel-partners'
import './lib/carousel-central'
import './lib/modal'
import './lib/video-item'
import './lib/custom-anchor-system'


Alpine.data(cookieBar.name, cookieBar.data)
window.Alpine = Alpine

Alpine.start()

