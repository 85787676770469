export default (() => {

  const carouselContainer = document.querySelectorAll('.section-partners-carousel');

  // eslint-disable-next-line
  if(!carouselContainer) return;

  carouselContainer.forEach(element => {
    // const items = element.querySelectorAll('.swiper-slide').length;
    const carousel = new Swiper(element.querySelector('.swiper-container'), {
      loop: false,
      watchOverflow: true,
      // autoplay: {
      //   delay: 5000,
      // },
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: element.querySelector('.btn-carousel-next'),
        prevEl: element.querySelector('.btn-carousel-prev'),
      },
      slidesPerView: 2,
      spaceBetween: 16,
      breakpoints: {
        640: {
          slidesPerView: 6,
          spaceBetween: 24,
        }
      }
    });
    carousel.init();
  });

})();
