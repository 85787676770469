import Alpine from 'alpinejs'

document.addEventListener('alpine:initializing', () => {
  Alpine.store('sidebar', {
    on: false,
    toggle() {
      this.on = ! this.on
    },
    close() {
      this.on = false
    }
  })
  Alpine.store('cookieBar', {
    isOpened: false,
    toggle() {
      this.isOpened = ! this.isOpened
    },
    close() {
      this.isOpened = false
    }
  })
})


