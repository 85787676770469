/* eslint-disable */
import Cookies from 'js-cookie'
const EVENT_ACCEPT_COOKIE = new Event('accept-cookie');
export const cookieBar = {
  name: 'cookieBar',
  data(data = {}) {
    let all;
    return {
      getCookieBar: Cookies.get('cookiebar') ? JSON.parse(Cookies.get('cookiebar')) : false,
      isOpen: false,
      notOpen: data.notOpen || false,
      all: Object.assign({}, data.dynamic),
      dynamic: data.dynamic || {},
      allParam: false,
      checkedAll: {},
      googletagmanager: {
        add() {
          if(typeof gtag == 'undefined') return
            gtag('consent', 'update', { 'add_storage': 'granted', 'analytics_storage': 'granted' });
        },
        remove() {
          if(typeof gtag == 'undefined') return
            gtag('consent', 'update', { 'add_storage': 'denied', 'analytics_storage': 'denied' });
        }
      },
      init() {
        for (const [key, value] of Object.entries(this.dynamic)) {
          if(typeof value === 'object') {
            this.dynamic[key] = [];
            this.checkedAll[key] = false

            this.$nextTick(() => {
              this.$watch(`checkedAll.${key}`, (value, oldValue) => this.dynamic[key] = value ? this.all[key] : [])
              this.$watch(`dynamic.${key}`, value => {
                if(!value.length) this.checkedAll[key] = false
                if(!this.checkedAll[key] && value.length) {
                  const dataTime = this.dynamic[key]
                  this.checkedAll[key] = true
                  setTimeout(()=>{
                    this.dynamic[key] = dataTime
                  },10)
                }
              })
            });
          }
        }
        if (this.getCookieBar) {
          let consents = this.getCookieBar.consents
          delete consents['essential'];
          for (const [key, value] of Object.entries(consents)) {
            this.dynamic[key] = value
            this.checkedAll[key] = JSON.stringify(this.all[key]) == JSON.stringify(value)
          }
          this.checkAllParam()
          this.googletagmanagerFn()
        } else {
          if(!this.notOpen) this.$store.cookieBar.isOpened  = true
          // } else {
          //   document.dispatchEvent(EVENT_ACCEPT_COOKIE);
          // }
        };
      },
      accept(param) {
        this.$store.cookieBar.isOpened  = false
        this.googletagmanagerFn()
        Cookies.set('cookiebar', this.creatData(param), {
          expires: 180,
          path: '/'
        })

        document.dispatchEvent(EVENT_ACCEPT_COOKIE);
      },
      creatData(param) {
        const obj = {
          consents: {
            essential: ['essential-cookie'],
          },
          domainPath: window.location.host,
          expires: new Date(),
          uid: this.getCookieBar.uid ? this.getCookieBar.uid : this.guid(),
          version: '1'
        }
        this.essentialAlert = false
        if(param == 'all' || param == 'each') {
          const keyObj = param == 'all' ? 'all' : 'dynamic'
          for (const [key, value] of Object.entries(this.dynamic)) {
            if(typeof value === 'object') {
              obj.consents[key] = this[keyObj][key]
              if(param == 'all') {
                this.dynamic[key] = this.all[key]
              }
            }
          }
        }
        this.checkAllParam()
        return JSON.stringify(obj)
      },
      guid() {
        const _p8 = (s)=> {
            let p = (Math.random().toString(16)+"000000000").substr(2,8);
            return s ? "-" + p.substr(0,4) + "-" + p.substr(4,4) : p ;
        }
        return _p8() + _p8(true) + _p8(true) + _p8();
      },
      googletagmanagerFn() {
        if(this.dynamic.statistics.includes('googletagmanager')) {
          this.googletagmanager.add()
        } else {
          this.googletagmanager.remove()
        }
      },
      checkAllParam() {
        let allParam = []
        for (const [key, value] of Object.entries(this.dynamic)) {
          if(typeof value === 'object') {
            allParam.push(JSON.stringify(this.dynamic[key]) == JSON.stringify(this.all[key]))
          }
        }
        this.allParam = allParam.every(elem => elem == true)
      }
    }
  }
}
