export default {
    debounce: 100,
    btnSubmit: 'data-submit',
    attrs: {
        component: 'data-validation-component',
        for: 'data-validation-for',
        validationKey: 'data-validation-key'
    },
    templates: {
        error: {
            prefix: '<li>',
            suffix: '</li>'
        }
    },
    classnames: {
        valid: {},
        invalid: {}
    }
}