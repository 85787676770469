/* eslint-disable */
import {
    getData
} from './utils';
import defaultConfig from './defaults';
import eventsEmmiter from './events-emitter';
import ru from 'validatorjs/src/lang/ru';

const prototypes = {
    eventsEmmiter
  };
class Validation {
    constructor(form, rules, messages, config) {

        if (typeof form === "string") {
            form = document.querySelector(form);
        }

        this.validator = null;
        this.messages = messages;
        this.touched = new Set();
        this.config = Object.assign({}, defaultConfig, config);
        this.errors = {};
        this.rules = rules;
        this.form = form;
        this.refs = {};
        this.eventsListeners = {};
        this.timer = null;

        setTimeout(() => {
          this.init();
        }, this.config.debounce);

    }
    init() {
        this.buildRefsStore();
        this.registerEvents();
        this.validate();
    }
    render() {
        const data = getData(this.form);
        const {
            classnames
        } = this.config;

        this.errors = this.validator.errors.all();

        Object.keys(this.rules).forEach(name => {
            // Stop if we don't have refs to this field
            if (!(name in this.refs)) {
                return;
            }

            const fails = this.touched.has(name) && (name in this.errors);
            const passes = this.touched.has(name) && !fails && (name in data);

            if ('errors' in this.refs[name]) {
                if (passes) {
                    this.clearError(name);
                } else if (fails) {
                    this.printError(name);
                }
            }
            // Fixme
            Object.entries(this.refs[name]).forEach(([name, elements]) => {
                elements.forEach(element => {
                    Object.keys(classnames).forEach((classname) => {
                        if (!(name in classnames[classname])) return;
                        const classnamesArray = classnames[classname][name].split(' ');

                        if (classname === 'valid') {
                            if (passes) {
                                element.classList.add(...classnamesArray);
                            } else if (fails) {
                                element.classList.remove(...classnamesArray);
                            }
                        } else if (classname === 'invalid') {
                            if (fails) {
                                element.classList.add(...classnamesArray);
                            } else if (passes) {
                                element.classList.remove(...classnamesArray);
                            }
                        }
                    });
                });
            });
        });
    }
    addRule(obj, msg = {}) {
      this.rules = Object.assign(this.rules, obj);
      this.addMessage(msg);
      this.render();
      this.validate();
    }
    addMessage(msg) {
      this.messages = Object.assign(this.messages, msg);
    }
    removeRules(arr) {
      arr.forEach(rule => {
        delete this.rules[rule];
      })
      this.render();
      this.validate();
    }
    validate() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        const data = getData(this.form);
        this.validator = new Validator(data, this.rules, this.messages);

        Object.entries(data).forEach(([key, value]) => {
            if (null === value || 'undefined' === typeof value || '' === value) {
                return;
            }
            if (Array.isArray(value) && !value.length) {
                return;
            }
            // Don't mark as touched if it has errors in it
            if (key in this.refs &&
                'errors' in this.refs[key] &&
                this.refs[key].errors[0].childElementCount > 0
            ) {
                return;
            }
            this.touched.add(key);
        });

        this.validator.checkAsync(this.passes.bind(this), this.fails.bind(this));
      }, this.config.debounce)
    }
    fails() {
        this.form.querySelector(`[${this.config.btnSubmit}]`).disabled = true;
        this.render();
        this.emit('fails');
    }
    passes() {
        this.form.querySelector(`[${this.config.btnSubmit}]`).disabled = false;
        this.render();
        this.emit('passes');
    }
    printError(name) {
        this.refs[name].errors.forEach(field => {
            field.innerHTML = this.errors[name].map(message => this.errorHtml(message)).join('');
        })
    }
    clearError(name) {
        this.refs[name].errors.forEach(field => {
            field.innerHTML = '';
        })
    }
    errorHtml(message) {
        return `${ this.config.templates.error.prefix }
                    ${ message }
                ${ this.config.templates.error.suffix }`;
    }
    touch(e) {
        const name = e.target.getAttribute('name');
        if (name) {
            this.touched.add(name.replace(/\[]$/, ''));
        }
    }
    registerEvents() {
        this.form.addEventListener('input', this.validate.bind(this), true);
        this.form.addEventListener('change', this.validate.bind(this), true);
        this.form.addEventListener('submit', (e) => {
            if(this.validator.errorCount) {
                e.preventDefault();
                Object.keys(this.errors).forEach((key) => {
                    this.touched.add(key);
                });
                this.validate();
            }
        });
        Object.keys(prototypes['eventsEmmiter']).forEach((protoMethod) => {
            this.form[protoMethod] = prototypes['eventsEmmiter'][protoMethod].bind(this);
        });
    }
    buildRefsStore() {
        const components = this.form.querySelectorAll(`[${this.config.attrs.component}]`)

        components.forEach(element => {
            if (element.hasAttribute(this.config.attrs.for)) {
                const parent = element.getAttribute(this.config.attrs.for);
                const component = element.getAttribute(this.config.attrs.component);

                // Add the component to the refs
                this.addRef(parent, component, element);

                // If we have a validation key, let the element also be referenced by it
                if (element.hasAttribute(this.config.attrs.validationKey) &&
                    component !== element.getAttribute(this.config.attrs.validationKey)) {
                    this.addRef(parent, element.getAttribute(this.config.attrs.validationKey), element);
                }
            }
        });
    }
    addRef(parent, component, element) {
        this.refs[parent] = this.refs[parent] || {};
        this.refs[parent][component] = this.refs[parent][component] || [];
        this.refs[parent][component].push(element);
    }
}

Object.keys(prototypes).forEach((prototypeGroup) => {
    Object.keys(prototypes[prototypeGroup]).forEach((protoMethod) => {
        Validation.prototype[protoMethod] = prototypes[prototypeGroup][protoMethod];
    });
});

export default Validation;
