import lazyframe from 'lazyframe/src/lazyframe';

export default (() => {

  const elements = document.querySelectorAll('.lazyframe');

  // eslint-disable-next-line
  if(!elements.length) return;

  lazyframe(elements, {
    apikey: 'AIzaSyBWTh4UZoiOsZGxyBsxZjpX3qG67wPFod8',
    debounce: 250,
  })
})();
