import bsn from 'bootstrap.native';
import { setCookie } from './utils';

export default (() => {
  function mycococ() {
    setCookie('popup_shown', true, {
      expires: 3600 * 700
    });
  }
  document.addEventListener("DOMContentLoaded", ()=> {
    const myModal = document.getElementById('modalShow');
    // eslint-disable-next-line
    if(!myModal) return;
    const modalTime = myModal.getAttribute('data-time');
    const myModalInstance = new bsn.Modal(myModal,{});
    setTimeout(()=> {
      myModalInstance.show();
    }, modalTime*1000 );
    myModal.querySelector('.close').addEventListener("click", mycococ, { passive: false });
    myModal.querySelector('.close').addEventListener("touchstart", mycococ, { passive: false });
  });

})();
