export default (() => {
  const panels = document.querySelectorAll('.panel-default')
  // eslint-disable-next-line
  if(!panels) return;
  panels.forEach(element => {
    if(element.querySelector('.panel-collapse').classList.contains('in')) {
      element.closest('.panel-default').classList.add('is-open')
    }
    element.querySelector('.panel-collapse').addEventListener('show.bs.collapse', (event) =>{
      event.target.closest('.panel-default').classList.add('is-open')
    }, false);
    element.querySelector('.panel-collapse').addEventListener('hide.bs.collapse', (event) =>{
      event.target.closest('.panel-default').classList.remove('is-open')
    }, false);
  });
})();
