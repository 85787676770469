export default (() => {

  const carouselContainer = document.querySelector('.promo-carousel');

  // eslint-disable-next-line
  if(!carouselContainer) return;

  const items = carouselContainer.querySelectorAll('.swiper-slide').length;
  const carousel = new Swiper(carouselContainer.querySelector('.swiper-container'), {
    loop: items > 1,
    watchOverflow: items === 1,
    // autoplay: {
    //   delay: 5000,
    // },
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    observer: true,
    observeParents: true,
    navigation: {
      nextEl: carouselContainer.querySelector('.btn-carousel-next'),
      prevEl: carouselContainer.querySelector('.btn-carousel-prev'),
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    }
  });
  carousel.init();

})();
