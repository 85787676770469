/* eslint-disable */
export default (() => {

  let $page = document.querySelector('.main-map');

  if(!$page) return;

  let $map = document.querySelector('.map'),
      zoom = parseInt($map.getAttribute('data-zoom'), 10),
      pin = $map.getAttribute('data-pin'),
      icon = {
          url: pin,
      },
      map = new google.maps.Map($map, {
          zoom: zoom,
          disableDefaultUI: true,
          mapTypeControl: false,
          streetViewControl: false,
      });

  let lat = $map.getAttribute("data-lat"),
      lng = $map.getAttribute("data-lng"),
      marker = new google.maps.Marker({
          position: {lat: parseFloat(lat), lng: parseFloat(lng)},
          map,
          icon
      })

  map.setCenter({lat: parseFloat(lat), lng: parseFloat(lng)});

})();

