import Validator from 'validatorjs';
// import ru from 'validatorjs/src/lang/ru';
import Validation from './validation';

const krakenValidation = {
  start(form, rules, messages, config = {}) {
    const instanceValidation = new Validation(form, rules, messages, config)
    document.querySelector(form).validation = instanceValidation;
    return instanceValidation;
  }
}
Validator.registerMissedRuleValidator(() => true, '');
// Validator.useLang('ru');
// Validator.setMessages('ru', ru);

window.Validator = Validator;
window.Validation = krakenValidation;

export default krakenValidation;
