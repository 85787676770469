/* eslint-disable */
export function getData (form) {
    const formData = new FormData(form);
	const values = {};
	for (const [key, value] of formData.entries()) {
		const name = key.replace(/\[]$/, '');
		const multiple = name !== key;
		if (values[name]) {
			if (!(values[name] instanceof Array)) {
				values[name] = [values[name]];
			}
			values[name].push(value);
		} else {
			values[name] = multiple ? [value] : value;
		}
	}

	return values;
}
export function debounce(func, wait) {
    let timeout
    return function () {
        const context = this; const args = arguments
        const later = function () {
            timeout = null
            func.apply(context, args)
        }
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
    }
}
